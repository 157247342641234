<template>
  <section >
    <div class="grid md:grid-9-3 ">
      <div class="h-65vh position-relative">
        <transition name="fade" v-for="(boat,i) in boats">
          <div style="background-size: cover; overflow: hidden; position: absolute; z-index:1; top:0; background-position: center center"
               :style="{backgroundImage:'url('+$store.state.boatModel.productImage+')'}"  v-if="$store.state.boatModel.id===boat.id" class="w-100p h-100p">
          </div>
        </transition>
      </div>
      <div class="m-l-2 p-3 d-flex f-column md:h-65vh">
        <selection-header  :steps="6" :current-step="1"/>
        <total-runner :step="1" class="f-1"/>
        <bottom-nav to="/exterior"/>
      </div>
    </div>

    <tab-shop :hide-price="true" :item="selectedBoat" selected-tab="boat" :multi-items="{boat:boats}" @update="selectedBoat = $event.item">Choose your</tab-shop>
  </section>
</template>

<style>

</style>

<script>
import api from "@/api";
import TabShop from "@/components/TabShop"
import totalRunner from "@/components/totalRunner"
import selectionHeader from "@/components/selectionHeader";
import BottomNav from "@/components/BottomNav";

export default {
  name: 'Home',
  components: {selectionHeader, totalRunner, BottomNav, TabShop},

  data: () => ({
    boats:[],
    selectedBoat: null
  }),
  watch:{
    selectedBoat(newVal){
      if(newVal){
        this.$store.commit('setBoatModel', newVal)
      }
    }
  },
  async mounted() {
      this.boats = this.$store.getters.boats;
      const initial = this.boats.find(x => {
          return this.$route.params.preSelected.length === 1 && Number(x.id) === Number(this.$route.params.preSelected[0])
      });
      let preSelected;
      if(this.$store.state.boatModel && typeof this.$store.state.boatModel.id !== 'undefined'){
          preSelected = {...this.$store.state.boatModel}
      } else if(initial){
          preSelected = initial
      } else {
          preSelected = {...this.boats[0]}
      }
      this.selectedBoat = preSelected;
      this.$store.commit('setBoatModel', preSelected)
  },

}
</script>
